import $ from 'jquery';

$(function () {
	const header = $('#header');
	const gnav = $('#header-gnav');
	const navbar = $('#header-navbar');

	if (!header.length) {
		return;
	}

	navbar.on('click', function () {
		navbar.toggleClass('is-active');
		if (navbar.hasClass('is-active')) {
			gnav.addClass('is-active');
			window.common.bodyFixed(true);
		} else {
			window.common.bodyFixed(false);
			gnav.removeClass('is-active');
		}
	});

	const scrollEvent = function () {
		if (!window.common.isBodyFixed()) {
			let scrollLeft = $(window).scrollLeft();
			//gnav.css('left', (scrollLeft * -1) + 'px');
			header.css('left', (scrollLeft * -1) + 'px');
		}
	}

	$(window).on('scroll', scrollEvent);
	scrollEvent();
});
