import $ from 'jquery';

$('.js-mv1-video').on('click', function () {
	const videoElem = $('video', this);
	if (videoElem.length) {
		const video = videoElem.get(0);
		if (video.paused) {
			video.play();
			$(this).removeClass('is-pause').addClass('is-play');
		} else {
			video.pause();
			$(this).removeClass('is-play').addClass('is-pause');
		}
	}
});

$('[data-muted]').on('click', function () {
	const videoId = $(this).data('muted');
	const videoElem = $('#' + videoId);
	if (videoElem.length) {
		const video = videoElem.get(0);
		video.muted = !video.muted;
		if (video.muted) {
			$(this).addClass('is-muted');
		} else {
			$(this).removeClass('is-muted');
		}
	}
});
