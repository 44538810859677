import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

$(function () {
	$('.js-swiper1').each(function () {
		let _this = this;
		new Swiper(this, {
			loop: false,
			autoHeight: true,
			slidesPerView: 1,
			spaceBetween: 50,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				768: {
					autoHeight: false,
					slidesPerView: 2,
					spaceBetween: 0,
				}
			}
		});
	});

	$('.js-list11-swiper').each(function () {
		let _this = this;
		new Swiper(this, {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 40,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				768: {
					slidesPerView: 3,
					spaceBetween: 0,
				}
			}
		});
	});
});
