import $ from 'jquery';

const common = {
	ww: window.innerWidth,
	wh: window.innerHeight,

	sp: function () {
		return window.innerWidth < 768;
	},

	pc: function () {
		return window.innerWidth >= 768;
	},

	getOffsetTop: function (domEl) {
		let offsetTop = domEl.offsetTop;
		if (domEl.offsetParent) {
			offsetTop += window.settings.getOffsetTop(domEl.offsetParent);
		}
		return offsetTop;
	},

	/* Get ScrollBar Width */
	scrollBarWidth: null,
	getScrollBarWidth: function (reset = false) {
		if (reset === true || common.scrollBarWidth === null) {
			let div = document.createElement("div");
			div.style.overflow = "scroll";
			div.style.width = "100px";
			div.style.height = "100px";
			div.style.visibility = "hidden";
			document.body.appendChild(div);
			common.scrollBarWidth = div.offsetWidth - div.clientWidth;
			document.body.removeChild(div);
		}
		return common.scrollBarWidth;
	},

	/* Resize X Event */
	resizeXTimeout: null,
	resizeXWidth: window.innerWidth,
	eventResizeX: function () {
		if (common.resizeXWidth !== window.innerWidth) {
			clearTimeout(common.resizeXTimeout);
			common.resizeXWidth = window.innerWidth;
			$(window).trigger("resize-x");
			document.body.classList.add("is-resizing");
			common.resizeXTimeout = setTimeout(function () {
				$(window).trigger("resize-x-end", [common.getScrollBarWidth(true)]);
				document.body.classList.remove("is-resizing");
			}, 200);
		}
	},

	/* Body Fixed */
	bodyFixedTop: 0,
	bodyFixedLeft: 0,
	bodyFixed: function (fixed = true) {
		if (fixed) {
			common.bodyFixedTop = $(window).scrollTop();
			common.bodyFixedLeft = $(window).scrollLeft();
			let fixedLeft = 0;
			let overflowX = 'auto';
			if (common.bodyFixedLeft > 0) {
				fixedLeft = common.bodyFixedLeft;
				overflowX = 'scroll';
			}
			document.body.style.position = 'fixed';
			document.body.style.top = (common.bodyFixedTop * -1) + 'px';
			document.body.style.left = fixedLeft * -1 + "px";
			document.body.style.overflowX = overflowX;
			document.body.style.overflowY = 'hidden';
			document.body.classList.add('is-fixed');
		} else {
			document.body.removeAttribute('style');
			document.body.classList.remove('is-fixed');
			window.scrollTo(common.bodyFixedLeft, common.bodyFixedTop);
		}
	},
	isBodyFixed: function () {
		return document.body.classList.contains("is-fixed");
	},
};

window.common = Object.create(common);

$(window).on("load", function () {
	$(window).on("resize orientationchange", window.common.eventResizeX);
	window.common.resizeXWidth = window.innerWidth;
	$(window).trigger("resize-x");
});

$(function () {
	const doc = document.documentElement
	doc.style.setProperty('--ww', `${window.common.ww}px`);
	doc.style.setProperty('--wh', `${window.common.wh}px`);
});
