import $ from 'jquery';

$(function () {
	let timeoutOpen = null;
	$('[data-popup1]').on('click', function () {
		let name = $(this).data('popup1');
		let modal = $('.c-popup1#' + name);
		if (modal.length) {
			clearTimeout(timeoutOpen);
			modal.css('display', 'block');
			timeoutOpen = setTimeout(function () {
				modal.addClass('show');
				if (!window.common.isBodyFixed()) {
					window.common.bodyFixed(true);
				}
			}, 150);
		}
	});

	let timeoutClose = null;
	$('.js-popup1-close').on('click', function () {
		const modal = $(this).parents('.c-popup1');
		if (modal.length) {
			clearTimeout(timeoutClose);
			if (!$('#header-gnav').hasClass('is-active')) {
				window.common.bodyFixed(false);
			}
			modal.removeClass('show');
			timeoutClose = setTimeout(function () {
				modal.css('display', 'none');
			}, 300);
		}
	});
});
